var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import "./index.scss";
import { CloseIcon } from "../../../images/icon";
import { parseJSON } from "../../helpers/tokenHelper";
import { fetchUserObject } from "../../helpers/store-manager/fetchStore.js";
import pubsub from '@viewlift/pubsub';
import Spinner from "../../components/HomeLoadingScreen/Spinner";
import { ANALYTICS } from "../../helpers/analyticsMap.js";
var PersonalizationBanner = function (prop) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    if (typeof window === "undefined")
        return;
    var isPersonlizationEnabled = (((_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.personalization) === null || _c === void 0 ? void 0 : _c.enablePersonalization) && ((_f = (_e = (_d = window === null || window === void 0 ? void 0 : window.app_data) === null || _d === void 0 ? void 0 : _d.appcmsMain) === null || _e === void 0 ? void 0 : _e.personalization) === null || _f === void 0 ? void 0 : _f.enablePersonalizationBanner));
    if (!isPersonlizationEnabled)
        return;
    var isHomePage = (((_g = window === null || window === void 0 ? void 0 : window.location) === null || _g === void 0 ? void 0 : _g.pathname) === "/");
    if (!isHomePage)
        return;
    var location = useLocation();
    var _x = __read(useState(true), 2), showPersonalizationBanner = _x[0], setShowPersonalizationBanner = _x[1];
    var _y = __read(useState(null), 2), user = _y[0], setUser = _y[1];
    var _z = __read(useState(false), 2), isLoading = _z[0], setIsLoading = _z[1];
    var genericMessage = (_j = (_h = window === null || window === void 0 ? void 0 : window.app_data) === null || _h === void 0 ? void 0 : _h.appcmsMain) === null || _j === void 0 ? void 0 : _j.genericMessages;
    var hasPersonalizationContent = (((_o = (_m = (_l = (_k = user === null || user === void 0 ? void 0 : user.profiles) === null || _k === void 0 ? void 0 : _k[0]) === null || _l === void 0 ? void 0 : _l.contentPersonalization) === null || _m === void 0 ? void 0 : _m.teams) === null || _o === void 0 ? void 0 : _o.length) > 0) ||
        (((_s = (_r = (_q = (_p = user === null || user === void 0 ? void 0 : user.profiles) === null || _p === void 0 ? void 0 : _p[0]) === null || _q === void 0 ? void 0 : _q.contentPersonalization) === null || _r === void 0 ? void 0 : _r.persons) === null || _s === void 0 ? void 0 : _s.length) > 0) ||
        (((_u = (_t = user === null || user === void 0 ? void 0 : user.contentPersonalization) === null || _t === void 0 ? void 0 : _t.teams) === null || _u === void 0 ? void 0 : _u.length) > 0) ||
        (((_w = (_v = user === null || user === void 0 ? void 0 : user.contentPersonalization) === null || _v === void 0 ? void 0 : _v.persons) === null || _w === void 0 ? void 0 : _w.length) > 0);
    var bannerText = hasPersonalizationContent ?
        ((genericMessage === null || genericMessage === void 0 ? void 0 : genericMessage.personalisationBannerText) || "This page is based on your interests. You can edit your personalization preferences at any time in your ") :
        ((genericMessage === null || genericMessage === void 0 ? void 0 : genericMessage.nonPersonalisationBannerText) || "You have not selected the favourite team yet, so to choose favourite visit ");
    var bannerHyperlink = hasPersonalizationContent ?
        ((genericMessage === null || genericMessage === void 0 ? void 0 : genericMessage.personalisationBannerHyperlink) || "Account Settings") :
        ((genericMessage === null || genericMessage === void 0 ? void 0 : genericMessage.nonPersonalisationBannerHyperlink) || "Personalization");
    var doesUserClosedPersonalizedBanner = parseJSON(localStorage.getItem("hidePersonalizationBanner"));
    useEffect(function () {
        pubsub.subscribe('isPersonalizationContentUpdated', displayPersonalizationBanner);
        getUser();
        return function () {
            pubsub.unsubscribe('isPersonalizationContentUpdated', displayPersonalizationBanner);
        };
    }, []);
    useEffect(function () {
        var pageRefreshStatus = parseJSON(localStorage.getItem("pageRefreshStatus"));
        if ((location === null || location === void 0 ? void 0 : location.pathname) === "/" && pageRefreshStatus) {
            localStorage.setItem("pageRefreshStatus", JSON.stringify(false));
            window.location.reload();
            setIsLoading(true);
        }
    }, []);
    useEffect(function () {
        if (!user)
            return;
        var eventData = { text: (hasPersonalizationContent ? 'personalized' : 'non personalized') };
        pubsub.publish('user-analytics', { ename: ANALYTICS.PERSONALIZATION_POP_UP, data: eventData });
    }, [user]);
    function displayPersonalizationBanner(bannerVisibilityState) {
        localStorage.setItem("hidePersonalizationBanner", JSON.stringify(!bannerVisibilityState));
        setShowPersonalizationBanner(bannerVisibilityState);
    }
    function getUser() {
        return __awaiter(this, void 0, void 0, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchUserObject()];
                    case 1:
                        user = _a.sent();
                        if (!user)
                            return [2 /*return*/];
                        setUser(user);
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleLinkClick() {
        var eventData = hasPersonalizationContent ? { "edit": "personalized" } : { "edit": "non personalized" };
        pubsub.publish('user-analytics', { ename: ANALYTICS.PERSONALIZATION_EDIT_POP_UP, data: eventData });
    }
    if (isLoading)
        return React.createElement(Spinner, null);
    if (!user)
        return;
    if (!showPersonalizationBanner)
        return;
    if (doesUserClosedPersonalizedBanner)
        return;
    return (React.createElement("div", { className: 'personalization-banner' },
        React.createElement("div", { className: 'banner-text' },
            React.createElement("div", null,
                bannerText,
                React.createElement(Link, { to: "/user-personalization", className: 'hyperlink', onClick: handleLinkClick }, bannerHyperlink))),
        React.createElement("div", { className: 'cancel-icon' },
            React.createElement(CloseIcon, { onClick: function () { return displayPersonalizationBanner(false); } }))));
};
export default PersonalizationBanner;
