import localforage from "localforage";

export function registerCustomSW() {
  const clevertapId= window?.app_data?.appcmsPlatform?.notifications.clevertap.accountId;
  const isCleverTapEnabled = (clevertapId !== null && clevertapId !== "") ? true : false ;
    if ('serviceWorker' in navigator) {
       window.addEventListener('load', () => {
        isCleverTapEnabled && navigator.serviceWorker.register('../service-worker.js', { scope: '/' })
        .then((registration) => {
          console.log('sw registered', registration);
        })
        .catch((error) => {
          console.error('Custom Service Worker registration failed', error);
        });

        //   add event listner for firebase
        navigator.serviceWorker.register("../firebase-messaging-sw.js")
        .then(() => {
            console.log("SW file registered")
        })
        .catch(err => {
            console.log("Error Registering Service Worker"+err)
        });
        });
        }
}

export const initializeWebWorker=async()=>{
  const clevertapId= window?.app_data?.appcmsPlatform?.notifications.clevertap.accountId;
  const isCleverTapEnabled = (clevertapId !== null && clevertapId !== "") ? true : false ;
  const AuthenticationStore = await localforage.getItem('AuthenticationStore')
  let userInfo = AuthenticationStore?.user
  const clevertapWebManifest = {
    "gcm_user_visible_only": true,
    "gcm_sender_id": userInfo ? window?.app_data?.appcmsPlatform?.notifications?.webPush?.gcmSenderID : window?.app_data?.appcmsPlatform?.notifications?.anonymousUserWebPush.gcmSenderID
  }
  if (isCleverTapEnabled) {
    const blob = new Blob([JSON.stringify(clevertapWebManifest)], { type: 'application/json' })
    const manifestURL = URL.createObjectURL(blob)
    // document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
  }
}


export const requestUserPermission = () => {
  const webPush = window?.app_data?.appcmsPlatform?.notifications?.webPush
	if(window.clevertap){
		window.clevertap.notifications.push({
			"titleText": webPush.titleText,
			"bodyText": webPush.bodyText,
			"okButtonText": webPush.okButtonText,
			"rejectButtonText": webPush.rejectButtonText,
			"okButtonColor": window?.app_data?.appcmsMain?.brand?.cta?.primary.textColor,
			"serviceWorkerPath": '/service-worker.js'
		})		
	}
}


export const anonymousUserPushNotification = () => {
  const anonymousUserWebPush = window.app_data.appcmsPlatform.notifications.anonymousUserWebPush
	if(window?.clevertap){
		window?.clevertap?.notifications.push({
			"titleText": anonymousUserWebPush.titleText,
			"bodyText": anonymousUserWebPush.bodyText,
			"okButtonText": anonymousUserWebPush.okButtonText,
			"rejectButtonText": anonymousUserWebPush.rejectButtonText,
			"okButtonColor": window?.app_data?.appcmsMain?.brand?.cta?.primary.textColor,
			"serviceWorkerPath": '/service-worker.js'
		})		
	}
}