var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { init, browserTracingIntegration, replayIntegration } from '@sentry/react';
import logger from './logger';
function validateUrl(url) {
    var p = /^(http|https):\/\/[^ "]+$/;
    return p.test(url);
}
export var Instrumentation;
(function (Instrumentation) {
    function getWebConfig() {
        var _a, _b, _c;
        var config = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsPlatform) === null || _b === void 0 ? void 0 : _b.observability) === null || _c === void 0 ? void 0 : _c.sentryConfig;
        if (!config || !(config === null || config === void 0 ? void 0 : config.enable) || (config === null || config === void 0 ? void 0 : config.platform) !== 'web')
            return null;
        return config;
    }
    function initSentry() {
        var _a, _b;
        var config = getWebConfig();
        if (!config)
            return logger('Sentry is not enabled for web');
        if (!(config === null || config === void 0 ? void 0 : config.dsn) || !validateUrl(config.dsn))
            return logger('Sentry DSN is not valid');
        logger('Sentry is enabled for web');
        var domains = ((_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.domainNames) || [];
        init({
            dsn: config.dsn,
            integrations: [browserTracingIntegration(), replayIntegration()],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: __spreadArray(['localhost'], __read(domains), false),
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
    Instrumentation.initSentry = initSentry;
})(Instrumentation || (Instrumentation = {}));
