var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { lazy, Suspense, useState, useRef } from "react";
import { parseJSON } from "../../helpers/tokenHelper";
import Snackbar from "../../components/Snackbar";
import Spinner from '../../components/HomeLoadingScreen/Spinner';
var SplashDownloadApp = lazy(function () { return import("./SplashDownloadApp"); });
var SplashNewsletter = lazy(function () { return import("./SplashNewsletter"); });
var SplashNewsletterAndSignUp = lazy(function () { return import("./SplashNewsletterAndSignUp"); });
var SplashPromotionalOffer = lazy(function () { return import("./SplashPromotionalOffer"); });
var SplashSchedule = lazy(function () { return import("./SplashSchedule"); });
var SplashScreenMap = {
    splashDownloadApp: SplashDownloadApp,
    splashNewsLetter: SplashNewsletter,
    splashNewsletterAndSignUp: SplashNewsletterAndSignUp,
    splashPromotionalOffer: SplashPromotionalOffer,
    splashSchedule: SplashSchedule,
};
export function dismissSplashScreen(overlayId) {
    var overlay = document.getElementById(overlayId);
    if (overlay) {
        overlay.classList.add("dismiss");
    }
    localStorage.setItem('dismissSplash', JSON.stringify(true));
}
export default function SplashLoader(_a) {
    var _b, _c;
    var name = _a.name;
    var isHomePage = (((_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.pathname) === "/");
    if (!isHomePage)
        return;
    var doesUserClosedSplashScreen = (_c = parseJSON(localStorage.getItem('dismissSplash'))) !== null && _c !== void 0 ? _c : false;
    if (doesUserClosedSplashScreen)
        return;
    var _d = __read(useState(false), 2), closeSplash = _d[0], setCloseSplash = _d[1];
    var _e = __read(useState(false), 2), isLoading = _e[0], setIsLoading = _e[1];
    var ref = useRef(null);
    var logMessage = function (type, message) {
        var _a;
        (_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.openSnackbar({ message: message, type: type }, 4000);
    };
    var handleSpinner = function (loadingState) {
        setIsLoading(loadingState);
    };
    if (closeSplash) {
        localStorage.setItem('dismissSplash', JSON.stringify(true));
    }
    var Screen = SplashScreenMap[name];
    return (React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
        React.createElement("div", null,
            isLoading && React.createElement(Spinner, null),
            React.createElement(Snackbar, { ref: ref }),
            !closeSplash && React.createElement(Screen, { closeSplashScreen: function () { return setCloseSplash(true); }, setIsLoading: handleSpinner, logMessage: logMessage }))));
}
;
