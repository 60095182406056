const ketchCookieConsent = () => {
    const isKetchCookieConsentEnable = window?.app_data?.appcmsPlatform?.privacy?.ketchCookie?.enable || false
    const orgName = (window?.app_data?.appcmsPlatform?.privacy?.ketchCookie?.orgCode) || null;
    const webPropertyCode = (window?.app_data?.appcmsPlatform?.privacy?.ketchCookie?.propertyCode) || null;
    const baseUrl = (window?.app_data?.appcmsPlatform?.privacy?.ketchCookie?.baseUrl) || null;
   
    if(isKetchCookieConsentEnable && webPropertyCode && orgName){
      const ketchUrl = baseUrl ? baseUrl : `https://global.ketchcdn.com/web/v2/config/${orgName}/${propertyCode}/boot.js`
      console.log("initiate ketch cookie consent")
      const params = new URLSearchParams(document.location.search);
      const propertyCode = (params && params.has("property")) ? params.get("property") : webPropertyCode;   
      
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = ketchUrl;
      script.defer = true;
      script.async = true;
  
      document.head.appendChild(script);
    }
}
export const handleKetchSetting = () => {
    try{
      if(window.semaphore){
        window.semaphore.push(['showPreferences', { tab: 'rightsTab' }])
      }
    }catch(e){
      console.log("ketch error:", e)
    }
}

export default ketchCookieConsent