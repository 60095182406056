import pino from 'pino';
var logger = pino({
    level: process.env.NODE_ENV === 'development' ? 'debug' : 'info',
    browser: {
        asObject: true,
    },
    base: null,
    timestamp: pino.stdTimeFunctions.isoTime,
    formatters: {
        level: function (label) {
            return { level: label };
        },
    },
});
// Helper function to build the log object
var buildLogObject = function (message, context, level) {
    var logObject = {
        level: level,
        message: typeof message === 'string' ? message : 'Unknown message',
    };
    if (context) {
        if (typeof context === 'object' && Object.keys(context).length > 0) {
            logObject.context = context;
        }
        else if (typeof context === 'string') {
            logObject.context = { description: context };
        }
    }
    if (typeof message === 'object' && message !== null) {
        logObject.details = message;
    }
    if (context && typeof context === 'object' && Object.keys(context).length > 0) {
        logObject.context = context;
    }
    return logObject;
};
// Main logger function
var log = function (message, level, context) {
    if (level === void 0) { level = 'info'; }
    if (context === void 0) { context = null; }
    try {
        var logObject = buildLogObject(message, context, level);
        logger[level](logObject);
    }
    catch (error) {
        if (error instanceof Error) {
            logger.error({ message: 'Logger failed', error: error.message });
        }
        else {
            logger.error({ message: 'Logger failed with an unknown error', error: error });
        }
    }
};
export default log;
