var _a, _b, _c, _d, _e, _f;
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { App } from './app';
import { BrowserRouter } from "react-router-dom";
document
    .querySelectorAll('html > *:not(body, head), script[src*="extension://"], link[href*="extension://"]')
    .forEach(function (s) {
    s.parentNode.removeChild(s);
});
hydrateRoot(document, React.createElement(BrowserRouter, null,
    React.createElement(App, { xApiKey: window.xApiKey, cachedAPI: window.cachedAPI, sseBaseUrl: (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.apiConfig) === null || _c === void 0 ? void 0 : _c.sseBaseUrl, siteConfig: window.app_data, pageData: window.page_data, browserDetails: window.browserDetails, apiBaseUrl: (_f = (_e = (_d = window === null || window === void 0 ? void 0 : window.app_data) === null || _d === void 0 ? void 0 : _d.appcmsMain) === null || _e === void 0 ? void 0 : _e.apiConfig) === null || _f === void 0 ? void 0 : _f.apiBaseUrl, analyticsBaseUrl: window.analyticsBaseUrl, wssBaseUrl: window.wssBaseUrl, env: window.env, ip: window.ip, embedCodeToken: window === null || window === void 0 ? void 0 : window.embedCodeToken, toolsUrl: window === null || window === void 0 ? void 0 : window.toolsUrl, templateUrl: window === null || window === void 0 ? void 0 : window.templateUrl, errorObj: window === null || window === void 0 ? void 0 : window.errorObj })));
